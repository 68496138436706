import PropTypes from 'prop-types'
import React from 'react'
import { graphql, Link } from 'gatsby'

import withLocation from '../wrappers/withLocation'

import Termly from '../components/termly'

import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
  query CookiesPage($locale: String!) {
    allContentfulLegales(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          cookiesPolicy {
            json
          }
        }
      }
    }
  }
`

const CookiesPolicy = ({data}) => {
  const options = {
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.BOLD]: text => <b className='font-sans font-semibold'>{text}</b>
    },
    renderNode: {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (node, children) => <p className="mt-2 text-sm font-light leading-6 md:mt-4 md:text-lg md:leading-8 lg:mt-2">{children}</p>,
        // eslint-disable-next-line react/display-name
        [BLOCKS.HEADING_1]: (node, children) => <h1 className='font-serif text-base font-normal md:text-xl xxl:text-center'>{children}</h1>,
        // eslint-disable-next-line react/display-name
        [BLOCKS.HEADING_3]: (node, children) => <h3 className='font-serif text-base font-normal md:text-xl xxl:text-center'>{children}</h3>,
        // eslint-disable-next-line react/display-name
        [INLINES.HYPERLINK]: (node, children) => <a target='_blank' rel="noopener noreferrer" href={node.data.uri} className='font-semibold underline cursor-pointer hover:text-gray-400'>{children}</a>,
    }
  }

  return (<div className='max-w-sm px-5 mx-auto my-10 text-justify sm:max-w-lg md:max-w-2xl lg:max-w-3xl xl:max-w-4xl xxl:max-w-6xl'>
      <div className='flex justify-around my-5 flex-column lg:flex-row'>
        <Link to='/' className='mx-1 text-center underline hover:text-blue-500'>Website</Link>
        <Link to='/terms' className='mx-1 text-center underline hover:text-blue-500'>Terms and conditions</Link>
        <Link to='/privacy-policy' className='mx-1 text-center underline hover:text-blue-500'>Privacy Policy</Link>
      </div>
      <div className='mb-5'>
        <Termly/>
      </div>
      {documentToReactComponents(data.allContentfulLegales.edges[0].node.cookiesPolicy.json, options)}
  </div>)
}

CookiesPolicy.propTypes = {
  data: PropTypes.object
}

export default withLocation(CookiesPolicy)
  